export default [
  {
    id: 1,
    label: "6 meses",
    unit: "M",
    quantity: 6,
  },
  {
    id: 2,
    label: "7 meses",
    unit: "M",
    quantity: 7,
  },
  {
    id: 3,
    label: "8 meses",
    unit: "M",
    quantity: 8,
  },
  {
    id: 4,
    label: "8 meses",
    unit: "M",
    quantity: 8,
  },
  {
    id: 5,
    label: "9 meses",
    unit: "M",
    quantity: 9,
  },
  {
    id: 6,
    label: "10 meses",
    unit: "M",
    quantity: 10,
  },
  {
    id: 7,
    label: "11 meses",
    unit: "M",
    quantity: 11,
  },
  {
    id: 8,
    label: "1 año",
    unit: "Y",
    quantity: 1,
  },
  {
    id: 9,
    label: "2 años",
    unit: "Y",
    quantity: 2,
  },
  {
    id: 10,
    label: "3 años",
    unit: "Y",
    quantity: 3,
  },
  {
    id: 11,
    label: "4 años",
    unit: "Y",
    quantity: 4,
  },
  {
    id: 11,
    label: "4 años",
    unit: "Y",
    quantity: 4,
  },
  {
    id: 12,
    label: "5 años",
    unit: "Y",
    quantity: 5,
  },
  {
    id: 13,
    label: "6 años",
    unit: "Y",
    quantity: 6,
  },
  {
    id: 14,
    label: "7 años",
    unit: "Y",
    quantity: 7,
  },
  {
    id: 15,
    label: "8 años",
    unit: "Y",
    quantity: 8,
  },
  {
    id: 15,
    label: "9 años",
    unit: "Y",
    quantity: 9,
  },
  {
    id: 16,
    label: "10 años",
    unit: "Y",
    quantity: 10,
  },
];
