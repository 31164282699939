<template>
  <v-container>

    <NavBar :step="3"/>    

    <Titulo></Titulo>
    
    

    <br>
    <br>

    <v-stepper v-model="e1" elevation="0">
      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step class="stepForms" style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Mascota
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="4"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br>

      <v-stepper-items>
        <v-stepper-content step="4">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col :class="vista">

                  <h3 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em; color: #002C77; margin-top: -5%;">
                    Ingresa tus datos</h3>
                  <h4 class="text-center"
                    style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                    Son necesarios para completar la póliza del seguro</h4>

                  <br>

                  <v-text-field v-model="userData.name" maxlength="51" @change="change()" :rules="nameRules"
                    label="Nombre" required  solo filled class="v-textField" ></v-text-field>

                  <br>

                  <v-text-field v-model="userData.surname" maxlength="51" @change="change()" :rules="surnameRules"
                    label="Apellido" required  solo filled class="v-textField" ></v-text-field>

                  <br>

                  <v-text-field v-model="userData.email" maxlength="51" @change="change()" :rules="emailRules" label="Email" required
                     solo filled class="v-textField"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.phoneNumber" maxlength="9" :counter="9" @change="change()"
                    :rules="phoneRules" prefix="+56" label="Teléfono" required  solo filled class="v-textField" ></v-text-field>

                  <br>

                  <v-text-field v-model.lazy="newRut" maxlength="13" @change="change()" :rules="rutRules"
                    label="Ingresa tu RUT ( sin punto y con guión)" required  solo filled class="v-textField" @input="changeRut"></v-text-field>

                  <br>

                  <v-text-field v-model="userData.serialNumber" maxlength="10" @change="change()"
                    :rules="serialNumberRules" label="Ingresa el número de Documento / Serie" required  solo filled class="v-textField">
                  </v-text-field>

                  <br>

                  <v-text-field v-model="userData.address" maxlength="51" @change="change()" :rules="addressRules"
                    label="Ingresa tu dirección" required  solo filled class="v-textField" ></v-text-field>

                  <br>

                  <v-select :items="regions" v-model="userData.region" @change="change()" :rules="regionRules"
                    :loading="loadingRegions" v-on:change="getCommunes()" item-text="name"
                    label="Selecciona tu región de residencia" required return-object  solo filled class="v-textField"></v-select>

                  <br>

                  <v-select :items="communes" v-model="userData.commune" :rules="communeRules" @change="change()"
                    :loading="loadingCommunes" :disabled="loadingCommunes || userData.region === null" item-text="name"
                    label="Selecciona tu comuna de residencia" required return-object  solo filled class="v-textField">
                  </v-select>

                  <br>
                  <br>

                  <div class="button-container">
                    <v-btn
                      style="background-color: rgba(0, 44, 119, 1); color: white; border-radius: 12px; width: 100%; height: 120%;"
                      @click="validate()">
                      Continuar
                    </v-btn>
                  </div>

                </v-col>

              </div>

              <!-- <v-row style="justify-content: center; margin-top: 1rem">
        <v-btn text @click="back()"> Volver </v-btn>
      </v-row> -->
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>

import CountryService from "@/services/country.service.js";
import RutUtils from "@/utils/rut.utils.js";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";
import ShieldService from "../services/shield.service";

import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    stepperColor: '#00968F',
    screen: window.screen.width,
    stepId: null,
    userData: {
      name: localStorage.getItem("name"),
      surname: localStorage.getItem("surname"),
      serialNumber: null,
      rut: null,
      region: null,
      commune: null,
      address: null,
      email: localStorage.getItem("email"),
      phoneNumber: localStorage.getItem("phoneNumber"),
      hasAcode: null,
      couponId: null,
      dates: null,
    },
    petData: {
      species: {
        name: null,
      },
      breed: null,
      petName: null,
      age: {
        label: null,
      },
      sex: {
        label: null,
      },
      hasMicrochip: false,
    },
    e1: 4,
    month: null,
    day: null,
    year: null,
    months: [],
    days: [],
    yars: [],
    valid: false,
    addressRules: [
      (v) => !!v || "Debes ingresar tu dirección",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    regionRules: [(v) => !!v || "Ingresa la region en la que vives con tu mascota",],
    communeRules: [(v) => !!v || "Necesitamos saber en que comuna vives",],
    rutRules: [
      (v) => !!v || "El RUT es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    serialNumberRules: [
      (v) => !!v || "El número de serie es requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres"
    ],

    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    phoneRules: [
      (v) => !!v || "El número telefónico es requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 9) || "Ingrese un número válido",
    ],
    regions: [],
    communes: [],
    newRut: "",
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
  }),
  mounted() {
    this.petData.species.name = localStorage.getItem('species')
    this.petData.breed = localStorage.getItem('breed')
    this.petData.name = localStorage.getItem('namepet')
    this.petData.age.label = localStorage.getItem('ageLabel')
    this.petData.sex.label = localStorage.getItem('sexLabel')
    this.petData.hasMicrochip = false
    this.stepId = localStorage.getItem('stepId')

    if (this.screen > 800) {
      this.vista = 'vista2'
    } else {
      this.vista = 'vista'
    }
    this.getRegions();
    this.getMonths();
    this.getYears();
  },
  methods: {
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    changeRut(rut) {
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    selectGender(gender) {
      this.userData.gender = gender;
    },
    validateGender(v) {
      if (this.userData.gender === true) {
        console.log(v)
        return false
      } else {
        console.log(v)
        return true;
      }
    },
    back() {
      this.$emit("back");
    },
    SaveDates(dates) {
      this.$refs.menu.save(dates)
    },
    validate() {
      if (this.$refs.form.validate()) {
        localStorage.setItem("name", this.userData.name);
        localStorage.setItem("surname", this.userData.surname);
        localStorage.setItem("email", this.userData.email);
        localStorage.setItem("phoneNumber", this.userData.phoneNumber);
        localStorage.setItem("dates", this.userData.dates);

        localStorage.setItem("rut", this.userData.rut);
        localStorage.setItem("serialNumber", this.userData.serialNumber);
        localStorage.setItem("address", this.userData.address);
        localStorage.setItem("commune", this.userData.commune.id);
        localStorage.setItem("region", this.userData.region.id);

        window.location.pathname = "/resumen";

      }
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.month) {
        this.loadingDays = true;
        this.day = null;
        this.days = await dateService.getDays(
          this.month.name
        );
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    async change() {

      var values;

      try {
        values = [
          this.userData.name,
          this.userData.surname,
          this.userData.serialNumber,
          this.userData.rut,
          this.userData.region.name,
          this.userData.commune.name,
          this.userData.address,
          this.userData.email,
          this.userData.phoneNumber,
          this.petData.species.name,
          this.petData.breed,
          this.petData.name,
          this.petData.age == null ? '' : this.petData.age.label,
          this.petData.sex == null ? '' : this.petData.sex.label,
          this.petData.hasMicrochip,
        ]
        var carro = await ShieldService.createStep(values, 4, 0, this.stepId)
        this.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart Fail");
      }

    }
  },
};
</script>

<style lang="scss">
.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-date {
  align-items: center;
  display: flex;
  margin-left: 0rem;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container header div div.desktop-header div.desktop-logo-container div a div i.v-icon.notranslate.mdi.mdi-chevron-left.theme--light {
  color: #002C77 !important;
}

.v-textField {
box-sizing: border-box;
height: 48px; 
margin: auto; 
background: #FFFFFF; 
border: 1.5px solid rgba(142, 142, 142, 0.2);
box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08); 
border-radius: 6px;
}

</style>