export default [
  {
    id: "ARICA",
    name: "Arica y Parinacota",
    romanNumber: "XV",
    number: 15,
    communes: [
      { id: "ARICA", name: "Arica" },
      { id: "CAMARONES", name: "Camarones" },
      { id: "GENERAL LAGOS", name: "General Lagos" },
      { id: "PUTRE", name: "Putre" },
    ],
  },
  {
    id: "TARAPACA",
    name: "Tarapacá",
    romanNumber: "I",
    number: 1,
    communes: [
      { id: "ALTO HOSPICIO", name: "Alto Hospicio" },
      { id: "CAMIÑA", name: "Camiña" },
      { id: "COLCHANE", name: "Colchane" },
      { id: "HUARA", name: "Huara" },
      { id: "IQUIQUE", name: "Iquique" },
      { id: "PICA", name: "Pica" },
      { id: "POZO ALMONTE", name: "Pozo Almonte" },
    ],
  },
  {
    id: "ANTOFAGASTA",
    name: "Antofagasta",
    romanNumber: "II",
    number: 2,
    communes: [
      { id: "ANTOFAGASTA", name: "Antofagasta" },
      { id: "CALAMA", name: "Calama" },
      { id: "MARIA ELENA", name: "María Elena" },
      { id: "MEJILLONES", name: "Mejillones" },
      { id: "OLLAGUE", name: "Ollagüe" },
      { id: "SAN PEDRO DE ATACAMA", name: "San Pedro de Atacama" },
      { id: "SIERRA GORDA", name: "Sierra Gorda" },
      { id: "TALTAL", name: "Taltal" },
      { id: "TOCOPILLA", name: "Tocopilla" },
    ],
  },
  {
    id: "ATACAMA",
    name: "Atacama",
    romanNumber: "III",
    number: 3,
    communes: [
      { id: "ALTO DEL CARMEN", name: "Alto del Carmen" },
      { id: "CALDERA", name: "Caldera" },
      { id: "CHANARAL", name: "Chañaral" },
      { id: "COPIAPO", name: "Copiapó" },
      { id: "DIEGO DE ALMAGRO", name: "Diego de Almagro" },
      { id: "FREIRINA", name: "Freirina" },
      { id: "HUASCO", name: "Huasco" },
      { id: "TIERRA AMARILLA", name: "Tierra Amarilla" },
      { id: "VALLENAR", name: "Vallenar" },
    ],
  },
  {
    id: "COQUIMBO",
    name: "Coquimbo",
    romanNumber: "IV",
    number: 4,
    communes: [
      { id: "ANDACOLLO", name: "Andacollo" },
      { id: "CANELA", name: "Canela" },
      { id: "COMBARBALA", name: "Combarbalá" },
      { id: "COQUIMBO", name: "Coquimbo" },
      { id: "ILLAPEL", name: "Illapel" },
      { id: "LA HIGUERA", name: "La Higuera" },
      { id: "LA SERENA", name: "La Serena" },
      { id: "LOS VILOS", name: "Los Vilos" },
      { id: "MONTE PATRIA", name: "Monte Patria" },
      { id: "OVALLE", name: "Ovalle" },
      { id: "PAIGUANO", name: "Paiguano" },
      { id: "PUNITAQUI", name: "Punitaqui" },
      { id: "RIO HURTADO", name: "Río Hurtado" },
      { id: "SALAMANCA", name: "Salamanca" },
      { id: "VICUNA", name: "Vicuña" },
    ],
  },
  {
    id: "VALPARAISO",
    name: "Valparaíso",
    romanNumber: "V",
    number: 5,
    communes: [
      { id: "ALGARROBO", name: "Algarrobo" },
      { id: "CABILDO", name: "Cabildo" },
      { id: "CALERA", name: "Calera" },
      { id: "CALLE LARGA", name: "Calle Larga" },
      { id: "CARTAGENA", name: "Cartagena" },
      { id: "CASABLANCA", name: "Casablanca" },
      { id: "CATEMU", name: "Catemu" },
      { id: "CON CON", name: "Concón" },
      { id: "EL QUISCO", name: "El Quisco" },
      { id: "EL TABO", name: "El Tabo" },
      { id: "HIJUELAS", name: "Hijuelas" },
      { id: "ISLA DE PASCUA", name: "Isla de Pascua" },
      { id: "JUAN FERNANDEZ", name: "Juan Fernández" },
      { id: "LA CRUZ", name: "La Cruz" },
      { id: "LA LIGUA", name: "La Ligua" },
      { id: "LIMACHE", name: "Limache" },
      { id: "LLAILLAY", name: "Llaillay" },
      { id: "LOS ANDES", name: "Los Andes" },
      { id: "NOGALES", name: "Nogales" },
      { id: "OLMUE", name: "Olmué" },
      { id: "PANQUEHUE", name: "Panquehue" },
      { id: "PAPUDO", name: "Papudo" },
      { id: "PETORCA", name: "Petorca" },
      { id: "PUCHUNCAVI", name: "Puchuncaví" },
      { id: "PUTAENDO", name: "Putaendo" },
      { id: "QUILLOTA", name: "Quillota" },
      { id: "QUILPUE", name: "Quilpué" },
      { id: "QUINTERO", name: "Quintero" },
      { id: "RINCONADA", name: "Rinconada" },
      { id: "SAN ANTONIO", name: "San Antonio" },
      { id: "SAN ESTEBAN", name: "San Esteban" },
      { id: "SAN FELIPE", name: "San Felipe" },
      { id: "SANTA MARIA", name: "Santa María" },
      { id: "SANTO DOMINGO", name: "Santo Domingo" },
      { id: "VALPARAISO", name: "Valparaíso" },
      { id: "VILLA ALEMANA", name: "Villa Alemana" },
      { id: "VINA DEL MAR", name: "Viña del Mar" },
      { id: "ZAPALLAR", name: "Zapallar" },
    ],
  },
  {
    id: "METROPOLITANA",
    name: "Metropolitana de Santiago",
    romanNumber: "XIII",
    number: 13,
    communes: [
      { id: "ALHUE", name: "Alhué" },
      { id: "BUIN", name: "Buin" },
      { id: "CALERA DE TANGO", name: "Calera de Tango" },
      { id: "CERRILLOS", name: "Cerrillos" },
      { id: "CERRO NAVIA", name: "Cerro Navia" },
      { id: "COLINA", name: "Colina" },
      { id: "CONCHALI", name: "Conchalí" },
      { id: "CURACAVI", name: "Curacaví" },
      { id: "EL BOSQUE", name: "El Bosque" },
      { id: "EL MONTE", name: "El Monte" },
      { id: "ESTACION CENTRAL", name: "Estación Central" },
      { id: "HUECHURABA", name: "Huechuraba" },
      { id: "INDEPENDENCIA", name: "Independencia" },
      { id: "ISLA DE MAIPO", name: "Isla de Maipo" },
      { id: "LA CISTERNA", name: "La Cisterna" },
      { id: "LA FLORIDA", name: "La Florida" },
      { id: "LA GRANJA", name: "La Granja" },
      { id: "LA PINTANA", name: "La Pintana" },
      { id: "LA REINA", name: "La Reina" },
      { id: "LAMPA", name: "Lampa" },
      { id: "LAS CONDES", name: "Las Condes" },
      { id: "LO BARNECHEA", name: "Lo Barnechea" },
      { id: "LO ESPEJO", name: "Lo Espejo" },
      { id: "LO PRADO", name: "Lo Prado" },
      { id: "MACUL", name: "Macul" },
      { id: "MAIPU", name: "Maipú" },
      { id: "MARIA PINTO", name: "María Pinto" },
      { id: "MELIPILLA", name: "Melipilla" },
      { id: "ÑUÑOA", name: "Ñuñoa" },
      { id: "PADRE HURTADO", name: "Padre Hurtado" },
      { id: "PAINE", name: "Paine" },
      { id: "PEDRO AGUIRRE CERDA", name: "Pedro Aguirre Cerda" },
      { id: "PEÑAFLOR", name: "Peñaflor" },
      { id: "PEÑALOLEN", name: "Peñalolén" },
      { id: "PIRQUE", name: "Pirque" },
      { id: "PROVIDENCIA", name: "Providencia" },
      { id: "PUDAHUEL", name: "Pudahuel" },
      { id: "PUENTE ALTO", name: "Puente Alto" },
      { id: "QUILICURA", name: "Quilicura" },
      { id: "QUINTA NORMAL", name: "Quinta Normal" },
      { id: "RECOLETA", name: "Recoleta" },
      { id: "RENCA", name: "Renca" },
      { id: "SAN BERNARDO", name: "San Bernardo" },
      { id: "SAN JOAQUIN", name: "San Joaquín" },
      { id: "SAN JOSE DE MAIPO", name: "San José de Maipo" },
      { id: "SAN MIGUEL", name: "San Miguel" },
      { id: "SAN PEDRO", name: "San Pedro" },
      { id: "SAN RAMON", name: "San Ramón" },
      { id: "SANTIAGO", name: "Santiago" },
      { id: "TALAGANTE", name: "Talagante" },
      { id: "TILTIL", name: "Tiltil" },
      { id: "VITACURA", name: "Vitacura" },
    ],
  },
  {
    id: "O'HIGGINS",
    name: "Libertador Gral. Bernardo O’Higgins",
    romanNumber: "VI",
    number: 6,
    communes: [
      { id: "CHIMBARONGO", name: "Chimbarongo" },
      { id: "CHEPICA", name: "Chépica" },
      { id: "CODEGUA", name: "Codegua" },
      { id: "COINCO", name: "Coinco" },
      { id: "COLTAUCO", name: "Coltauco" },
      { id: "DONIHUE", name: "Doñihue" },
      { id: "GRANEROS", name: "Graneros" },
      { id: "LA ESTRELLA", name: "La Estrella" },
      { id: "LAS CABRAS", name: "Las Cabras" },
      { id: "LITUECHE", name: "Litueche" },
      { id: "LOLOL", name: "Lolol" },
      { id: "MACHALI", name: "Machalí" },
      { id: "MALLOA", name: "Malloa" },
      { id: "MARCHIHUE", name: "Marchihue" },
      { id: "NANCAGUA", name: "Nancagua" },
      { id: "NAVIDAD", name: "Navidad" },
      { id: "OLIVAR", name: "Olivar" },
      { id: "PALMILLA", name: "Palmilla" },
      { id: "PAREDONES", name: "Paredones" },
      { id: "PERALILLO", name: "Peralillo" },
      { id: "PEUMO", name: "Peumo" },
      { id: "PICHIDEGUA", name: "Pichidegua" },
      { id: "PICHILEMU", name: "Pichilemu" },
      { id: "PLACILLA", name: "Placilla" },
      { id: "PUMANQUE", name: "Pumanque" },
      { id: "QUINTA DE TILCOCO", name: "Quinta de Tilcoco" },
      { id: "RANCAGUA", name: "Rancagua" },
      { id: "RENGO", name: "Rengo" },
      { id: "REQUINOA", name: "Requínoa" },
      { id: "SAN FERNANDO", name: "San Fernando" },
      { id: "MOSTAZAL", name: "San Francisco de Mostazal" },
      { id: "SAN VICENTE", name: "San Vicente de Tagua Tagua" },
      { id: "SANTA CRUZ", name: "Santa Cruz" },
    ],
  },
  {
    id: "MAULE",
    name: "Maule",
    romanNumber: "VII",
    number: 7,
    communes: [
      { id: "CAUQUENES", name: "Cauquenes" },
      { id: "CHANCO", name: "Chanco" },
      { id: "COLBUN", name: "Colbún" },
      { id: "CONSTITUCION", name: "Constitución" },
      { id: "CUREPTO", name: "Curepto" },
      { id: "CURICO", name: "Curicó" },
      { id: "EMPEDRADO", name: "Empedrado" },
      { id: "HUALANE", name: "Hualañé" },
      { id: "LICANTEN", name: "Licantén" },
      { id: "LINARES", name: "Linares" },
      { id: "LOGANVI", name: "Longaví" },
      { id: "MAULE", name: "Maule" },
      { id: "MOLINA", name: "Molina" },
      { id: "PARRAL", name: "Parral" },
      { id: "PELARCO", name: "Pelarco" },
      { id: "PELLUHUE", name: "Pelluhue" },
      { id: "PENCAHUE", name: "Pencahue" },
      { id: "RAUCO", name: "Rauco" },
      { id: "RETIRO", name: "Retiro" },
      { id: "ROMERAL", name: "Romeral" },
      { id: "RIO CLARO", name: "Río Claro" },
      { id: "SAGRADA FAMILIA", name: "Sagrada Familia" },
      { id: "SAN CLEMENTE", name: "San Clemente" },
      { id: "SAN JAVIER", name: "San Javier de Loncomilla" },
      { id: "SAN RAFAEL", name: "San Rafael" },
      { id: "TALCA", name: "Talca" },
      { id: "TENO", name: "Teno" },
      { id: "VICHUQUEN", name: "Vichuquén" },
      { id: "VILLA ALEGRE", name: "Villa Alegre" },
      { id: "YERBAS BUENAS", name: "Yerbas Buenas" },
    ],
  },
  {
    id: "ÑUBLE",
    name: "Ñuble",
    romanNumber: "XVI",
    number: 16,
    communes: [
      { id: "BULNES", name: "Bulnes" },
      { id: "CHILLAN VIEJO", name: "Chillán Viejo" },
      { id: "CHILLAN", name: "Chillán" },
      { id: "COBQUECURA", name: "Cobquecura" },
      { id: "COELEMU", name: "Coelemu" },
      { id: "COIHUECO", name: "Coihueco" },
      { id: "EL CARMEN", name: "El Carmen" },
      { id: "NINHUE", name: "Ninhue" },
      { id: "ÑIQUEN", name: "Ñiquén" },
      { id: "PEMUCO", name: "Pemuco" },
      { id: "PINTO", name: "Pinto" },
      { id: "PORTEZUELO", name: "Portezuelo" },
      { id: "QUILLON", name: "Quillón" },
      { id: "QUIRIHUE", name: "Quirihue" },
      { id: "RANQUIL", name: "Ránquil" },
      { id: "SAN CARLOS", name: "San Carlos" },
      { id: "SAN FABIAN", name: "San Fabián" },
      { id: "SAN IGNACIO", name: "San Ignacio" },
      { id: "SAN NICOLAS", name: "San Nicolás" },
      { id: "TREGUACO", name: "Treguaco" },
      { id: "YUNGAY", name: "Yungay" },
    ],
  },
  {
    id: "BIO-BIO",
    name: "Biobío",
    romanNumber: "VIII",
    number: 8,
    communes: [
      { id: "ALTO BIOBIO", name: "Alto Biobío" },
      { id: "ANTUCO", name: "Antuco" },
      { id: "ARAUCO", name: "Arauco" },
      { id: "CABRERO", name: "Cabrero" },
      { id: "CAÑETE", name: "Cañete" },
      { id: "CHIGUAYANTE", name: "Chiguayante" },
      { id: "CONCEPCION", name: "Concepción" },
      { id: "CONTULMO", name: "Contulmo" },
      { id: "CORONEL", name: "Coronel" },
      { id: "CURANILAHUE", name: "Curanilahue" },
      { id: "FLORIDA", name: "Florida" },
      { id: "HUALPEN", name: "Hualpén" },
      { id: "HUALQUI", name: "Hualqui" },
      { id: "LAJA", name: "Laja" },
      { id: "LEBU", name: "Lebu" },
      { id: "LOS ALAMOS", name: "Los Álamos" },
      { id: "LOS ANGELES", name: "Los Ángeles" },
      { id: "LOTA", name: "Lota" },
      { id: "MULCHEN", name: "Mulchén" },
      { id: "NACIMIENTO", name: "Nacimiento" },
      { id: "NEGRETE", name: "Negrete" },
      { id: "PENCO", name: "Penco" },
      { id: "QUILACO", name: "Quilaco" },
      { id: "QUILLECO", name: "Quilleco" },
      { id: "SAN PEDRO DE LA PAZ", name: "San Pedro de la Paz" },
      { id: "SAN ROSENDO", name: "San Rosendo" },
      { id: "SANTA BARBARA", name: "Santa Bárbara" },
      { id: "SANTA JUANA", name: "Santa Juana" },
      { id: "TALCAHUANO", name: "Talcahuano" },
      { id: "TIRUA", name: "Tirúa" },
      { id: "TOME", name: "Tomé" },
      { id: "TUCAPEL", name: "Tucapel" },
      { id: "YUMBEL", name: "Yumbel" },
    ],
  },
  {
    id: "ARAUCANIA",
    name: "Araucanía",
    romanNumber: "IX",
    number: 9,
    communes: [
      { id: "ANGOL", name: "Angol" },
      { id: "CARAHUE", name: "Carahue" },
      { id: "CHOLCHOL", name: "Cholchol" },
      { id: "COLLIPULLI", name: "Collipulli" },
      { id: "CUNCO", name: "Cunco" },
      { id: "CURACAUTIN", name: "Curacautín" },
      { id: "CURARREHUE", name: "Curarrehue" },
      { id: "ERCILLA", name: "Ercilla" },
      { id: "FREIRE", name: "Freire" },
      { id: "GALVARINNO", name: "Galvarino" },
      { id: "GORBEA", name: "Gorbea" },
      { id: "LAUTARO", name: "Lautaro" },
      { id: "LONCOCHE", name: "Loncoche" },
      { id: "LONQUIMAY", name: "Lonquimay" },
      { id: "LOS SAUCES", name: "Los Sauces" },
      { id: "LUMACO", name: "Lumaco" },
      { id: "MELIPEUCO", name: "Melipeuco" },
      { id: "NUEVA IMPERIAL", name: "Nueva Imperial" },
      { id: "PADRES LAS CASAS", name: "Padre las Casas" },
      { id: "PERQUENCO", name: "Perquenco" },
      { id: "PITRUFQUEN", name: "Pitrufquén" },
      { id: "PUCON", name: "Pucón" },
      { id: "PUREN", name: "Purén" },
      { id: "RENAICO", name: "Renaico" },
      { id: "SAAVEDRA", name: "Saavedra" },
      { id: "TEMUCO", name: "Temuco" },
      { id: "TEODORO SCHMIDT", name: "Teodoro Schmidt" },
      { id: "TOLTEN", name: "Toltén" },
      { id: "TRAIGUEN", name: "Traiguén" },
      { id: "VICTORIA", name: "Victoria" },
      { id: "VILCUN", name: "Vilcún" },
      { id: "VILLARRICA", name: "Villarrica" },
    ],
  },
  {
    id: "LOS RIOS",
    name: "Los Ríos",
    romanNumber: "XIV",
    number: 14,
    communes: [
      { id: "CORRAL", name: "Corral" },
      { id: "FUTRONO", name: "Futrono" },
      { id: "LA UNION", name: "La Unión" },
      { id: "LAGO RANCO", name: "Lago Ranco" },
      { id: "LANCO", name: "Lanco" },
      { id: "LOS LAGOS", name: "Los Lagos" },
      { id: "MARIQUINA", name: "Mariquina" },
      { id: "MAFIL", name: "Máfil" },
      { id: "PAILLACO", name: "Paillaco" },
      { id: "PANGUIPULLI", name: "Panguipulli" },
      { id: "RIO BUENO", name: "Río Bueno" },
      { id: "VALDIVIA", name: "Valdivia" },
    ],
  },
  {
    id: "LOS LAGOS",
    name: "Los Lagos",
    romanNumber: "X",
    number: 10,
    communes: [
      { id: "ANCUD", name: "Ancud" },
      { id: "CALBUCO", name: "Calbuco" },
      { id: "CASTRO", name: "Castro" },
      { id: "CHAITEN", name: "Chaitén" },
      { id: "CHONCHI", name: "Chonchi" },
      { id: "COCHAMO", name: "Cochamó" },
      { id: "CURACO DE VELEZ", name: "Curaco de Vélez" },
      { id: "DALCAHUE", name: "Dalcahue" },
      { id: "FRESIA", name: "Fresia" },
      { id: "FRUTILLAR", name: "Frutillar" },
      { id: "FUTALEUFU", name: "Futaleufú" },
      { id: "HUALAIHUE", name: "Hualaihué" },
      { id: "LLANQUIHUE", name: "Llanquihue" },
      { id: "LOS MUERMOS", name: "Los Muermos" },
      { id: "MAULLIN", name: "Maullín" },
      { id: "OSORNO", name: "Osorno" },
      { id: "PALENA", name: "Palena" },
      { id: "PUERTO MONTT", name: "Puerto Montt" },
      { id: "PUERTO OCTAY", name: "Puerto Octay" },
      { id: "PUERTO VARAS", name: "Puerto Varas" },
      { id: "PUQUELDON", name: "Puqueldón" },
      { id: "PURRANQUE", name: "Purranque" },
      { id: "PUYEHUE", name: "Puyehue" },
      { id: "QUEILEN", name: "Queilén" },
      { id: "QUELLON", name: "Quellón" },
      { id: "QUEMCHI", name: "Quemchi" },
      { id: "QUINCHAO", name: "Quinchao" },
      { id: "RIO NEGRO", name: "Río Negro" },
      { id: "S.J. DE LA COSTA", name: "San Juan de la Costa" },
      { id: "SAN PABLO", name: "San Pablo" },
    ],
  },
  {
    id: "AYSEN",
    name: "Aisén del Gral. Carlos Ibáñez del Campo",
    romanNumber: "XI",
    number: 11,
    communes: [
      { id: "AISEN", name: "Aisén" },
      { id: "CHILE CHICO", name: "Chile Chico" },
      { id: "CISNES", name: "Cisnes" },
      { id: "COCHRANE", name: "Cochrane" },
      { id: "COYHAIQUE", name: "Coyhaique" },
      { id: "GUAITECAS", name: "Guaitecas" },
      { id: "LAGO VERDE", name: "Lago Verde" },
      { id: "OHIGGINS", name: "O’Higgins" },
      { id: "RIO IBAÑEZ", name: "Río Ibáñez" },
      { id: "TORTEL", name: "Tortel" },
    ],
  },
  {
    id: "MAGALLANES",
    name: "Magallanes y de la Antártica Chilena",
    romanNumber: "XII",
    number: 12,
    communes: [
      { id: "ANTARTICA", name: "Antártica" },
      // { name: "Cabo de Hornos (Ex Navarino)" },
      { id: "LAGUNA BLANCA", name: "Laguna Blanca" },
      { id: "NATALES", name: "Natales" },
      { id: "PORVENIR", name: "Porvenir" },
      { id: "PRIMAVERA", name: "Primavera" },
      { id: "PUNTA ARENAS", name: "Punta Arenas" },
      { id: "RIO VERDE", name: "Río Verde" },
      { id: "SAN GREGORIO", name: "San Gregorio" },
      { id: "TIMAUKEL", name: "Timaukel" },
      { id: "T. DEL PAINE", name: "Torres del Paine" },
    ],
  },
];
