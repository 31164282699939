import dateData from "../utils/date-data";

export default class dateService {
  static async getMonths() {
    try {
      let months = dateData;
      return months;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los meses",
      };
    }
  }

  static async getDays(dateId) {
    try {
      let months;
      if (typeof dateId === "string" || dateId instanceof String) {
        months = dateData.find((element) => element.name === dateId);
      } else if (!isNaN(parseFloat(dateId)) && !isNaN(dateId - 0)) {
        months = dateData.find((element) => element.number === dateId);
      } else {
        return [];
      }
      let days = months.days ? months.days : [];
      return days;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener los días",
      };
    }
  }
}