
<template>
  <div style="background-color:#F9F9F9">
    <v-alert v-if="success === true" dense text type="success">
      ¡Has contratado tu seguro con éxito! Revisa tu <strong>correo electrónico</strong> para obtener toda la
      información.
    </v-alert>
    <v-alert v-if="success === false" dense text type="error">
      Ha ocurrido un error al contratar tu seguro. Intenta nuevamente.
    </v-alert>
    <hr style="max-width: 90%; margin: auto; background-color: #8E8E8E">
    <br>
    <div class="titulo text-center">
      <br>
      <label style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 24px;
          letter-spacing: -0.02em; color: #FFFFFF;">SEGURO DE SALUD</label>
      <br>
      <label style=" font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 24px;
          letter-spacing: -0.02em; color: #FFFFFF;">MASCOTAS LIBRE ELECCIÓN</label>
    </div>

    <v-stepper alt-labels style="background-color:#F9F9F9;" v-model="e1" elevation="0">
      <v-stepper-header style="box-shadow: none;">

        <v-stepper-step :complete="e1 > 1" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;
            letter-spacing: -0.01em; color: #8E8E8E;" v-if="e1 != 5" step="1"> Inicio</v-stepper-step>

        <v-divider style="min-width:25px" v-if="e1 != 5"></v-divider>

        <v-stepper-step :complete="e1 > 2" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;
            letter-spacing: -0.01em; color: #8E8E8E;" v-if="e1 != 5" step="2"> Mascotas </v-stepper-step>

        <v-divider style="min-width:25px" v-if="e1 != 5"></v-divider>

        <v-stepper-step :complete="e1 > 3" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;
            letter-spacing: -0.01em; color: #8E8E8E;" v-if="e1 != 5" step="3"> Plan </v-stepper-step>

        <v-divider style="min-width:25px" v-if="e1 != 5"></v-divider>

        <v-stepper-step style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 14px; line-height: 16px;
            letter-spacing: -0.01em; color: #8E8E8E;" v-if="e1 != 5" step="4"> Datos </v-stepper-step>

      <!-- <v-divider></v-divider>
        
          <v-stepper-step :complete="e1 > 5" step="5"> Resumen </v-stepper-step> -->

      </v-stepper-header>
      <v-stepper-items>
        <v-card class="mb-12" min-height="200px" elevation="0">
          <v-stepper-content style="background-color:#F9F9F9" step="1">
            <div v-if="loading" class="text-center">

              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
              <br><br>{{ cont }}
            </div>
            <Step1 v-else :userData="userData" @next-step="nextStep(1)" />
          </v-stepper-content>

          <v-stepper-content style="background-color:#F9F9F9" step="2">
            <div v-if="loading" class="text-center">

              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
            </div>
            <Step2 :petData="petData" @next-step="nextStep(2)" @back="back(2)" />

          </v-stepper-content>

          <v-stepper-content style="background-color:#F9F9F9" step="3">
            <div class="step-data">

              <h3 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 15px; letter-spacing: -0.01em;
                  color: #075596;">Estos son nuestros planes</h3>
              <h4 class="text-center"
                style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 16px; color: #232323; opacity: 0.75; padding: 2%;">
                Selecciona uno de los planes que tenemos para tu mascota</h4>
              <br>

              <v-row>
                &nbsp;&nbsp;&nbsp;
                <v-text-field v-model="userData.couponId" label="Ingresa aquí tu código de descuento" filled flat solo
                  style="min-width:30%; max-width: 70%; height: 48px; background: #FFFFFF; border: 1.5px solid rgba(142, 142, 142, 0.2); box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
                    border-radius: 10px 0 0 10px;"></v-text-field>

                <v-btn filled solo
                  style="width:80px; height: 55px; border-radius: 0px 10px 10px 0px; font-family: Helvetica; font-style: normal; font-weight: 700;
                    font-size: 14px; line-height: 143.34%; background-color:#FFB600; color:white; text-transform: capitalize ;"
                  @click="SendCouponID()">Aplicar</v-btn>

              </v-row>

              <br>
              <br>

              <v-form v-if="screen > 800" ref="form" v-model="planForm"
                style="display: flex; flex-wrap: wrap; justify-content: center;">
                <PlanEscritorio v-for=" (plan, index) in plans" :key="plan" :planData="plan" :index="index"
                  @next-step="selectPlan" />
              </v-form>

              <v-form v-if="screen < 800" ref="form" v-model="planForm"
                style="display: flex; flex-wrap: wrap; justify-content: center;">
                <PlanMovil :planData="plans" @next-step="selectPlan" />
              </v-form>
            </div>
          </v-stepper-content>

          <v-stepper-content style="background-color:#F9F9F9" step="4">
            <div v-if="loading" class="text-center">
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
            </div>
            <div v-else class="back-container">
            </div>
            <Step4 :userData="userData" @next-step="nextStep(4)" @back="back(4)" />
          </v-stepper-content>

          <v-stepper-content style="background-color:#F9F9F9" step="5">
            <div class="step-data">

              <div v-if="loading || loadingFinal" class="text-center">
                <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
              </div>

              <div v-else-if="!loading && !loadingFinal" style="max-width: 500px" class="tex-center">

                <h3 style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 23px;
                    letter-spacing: -0.01em; color: #FFB600; text-transform: uppercase;" class="text-center">
                  {{ userData.name }}, ESTAMOS A UN PASO!</h3>

                <br>

                <h4 class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 18px; line-height: 25px;
                    letter-spacing: -0.01em; color: #2670CA;">Antes de contratar te mostramos un
                  resumen del seguro de tu mascota
                </h4>

                <Step5 :userData="userData" :petData="petData" :planData="planData" @next-step="nextStep(5)"
                  @back="back(5)" />

              </div>

            </div>
          </v-stepper-content>

        </v-card>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import addIcon from "@/assets/profile-add.svg";
import PlanMovil from "@/components/PlanMovil.vue";
import PlanEscritorio from "@/components/PlanEscritorio.vue";

import Step1 from "@/components/Step1.vue";
import Step2 from "@/components/Step2.vue";
import Step4 from "@/components/Step4.vue";
import Step5 from "@/components/Finish.vue";

import Titulo from "../assets/images/titulo2.png"


import ShieldService from "@/services/shield.service.js";

export default {
  name: "Quote",
  components: {
    PlanEscritorio,
    PlanMovil,
    Step1,
    Step2,
    Step4,
    Step5,
  },

  data() {
    return {
      screen: window.screen.width,
      cont: "Cargando.  ",
      e1: 1,
      loading: false,
      loadingFinal: false,
      addIcon,
      Titulo,
      aplicar: "Aplicar",
      userData: {
        name: null,
        surname: null,
        serialNumber: null,
        rut: null,
        region: {
          name: null,
        },
        commune: {
          name: null,
        },
        address: null,
        email: null,
        phoneNumber: null,
        hasAcode: null,
        couponId: null,
        dates: null,

      },
      petData: {
        species: {
          name: null,
        },
        breed: null,
        petName: null,
        age: {
          label: null,
        },
        sex: {
          label: null,
        },
        hasMicrochip: null,

      },
      planData: {},
      planForm: true,
      success: null,

      newRut: "",
      valid: true,
      idForm: true,
      addressForm: true,
      loadingRegions: false,
      loadingCommunes: false,

      select: null,
      checkbox: false,
      menu: false,
      plans: [],

      selectedPlan: null,
      codeRules: [
        (v) => (!!v && v.length <= 20) || "Ingrese un código válido",
      ],
    };
  },
  methods: {
    async nextStep(step) {

      this.loading = true;
      if (step === 1) {

        document.body.scrollTop = document.documentElement.scrollTop = 0;

        const fechaActual = new Date(Date.now());
        var calculoYear = fechaActual.getFullYear() - this.userData.dates.slice(0, 4)

        if (calculoYear < 18) {
          this.$alert("El contratante no puede ser menor de edad", "Error", "error");
        } else {
          this.plans = await ShieldService.getPlans( //Espera el código ingresado en el step 1
            this.userData.couponId
          );

          if (this.plans.length === 0) {
            this.$alert("No se encontraron planes", "Información", "info")
          } else {
            this.e1 = 2;
          }
        }
      } else if (step === 2) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.e1 = 3;
      } else if (step === 3) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.e1 = 4;
      } else if (step === 4) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        this.e1 = 5;
      } else if (step === 5) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;

        // Conectar con SHIELD
        this.$alert("Completaste el Formulario!", "Formulario Completo", "success");
        this.loadingFinal = true;
        this.cont = "Estamos Procesando tu Solicitud . . ."
        try {
          const res = await ShieldService.createInsurance(
            this.userData,
            this.petData,
            this.planData,
          );

          window.location.href = res;
        } catch (error) {
          this.loadingFinal = false;
          this.loading = false;
          this.$alert("Ocurrió un error. Verifica que tus datos sean correctos", "Error", "error");
        }
      }
      this.loading = false;
    },
    back(step) {

      if (step === 2) {
        for (let x = 0; x < 10; x++) {
          this.plans.splice(x);
        }
        this.e1 = 1;
      } else if (step === 3) {
        for (let x = 0; x < 10; x++) {
          this.plans.splice(x);
        }
        this.e1 = 2;
      } else if (step === 4) {
        this.e1 = 3;
      } else if (step === 5) {
        this.e1 = 4;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    selectPlan(plan) {
      this.planData = plan;
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      this.e1 = this.e1 + 1;
    },
    async SendCouponID() {
      this.plans = await ShieldService.getPlans( //Espera el código ingresado en el step 1
        this.userData.couponId
      );

      if (this.plans.length === 0) {
        this.$alert("No se encontraron planes", "Información", "info")
      }

    },

  },
  mounted() {
    const query = this.$route.query;
    if (query) {
      if (query.success === "true") {
        this.success = true;
      } else if (query.success === "false") {
        this.success = false;
      }
    }
  }
};
</script>

<style lang="scss">
#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(1)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: 'Verdana';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(3)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: 'Verdana';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(5)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: 'Verdana';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
}

#app>div>main>div>div>div.v-stepper.v-stepper--alt-labels.v-sheet.theme--light.elevation-0>div.v-stepper__header>div:nth-child(7)>span {
  width: 39.89px;
  height: 40px;
  font-size: 2rem;
  margin-top: -8%;
  font-family: 'Verdana';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.01em;
  color: #FCFCFC;
}

.titulo {
  background-image: url("~@/assets/images/titulo2.png");
  max-width: 350px;
  height: 150px;
  margin: auto;
}

.v-input .v-label {
  font-size: 12px;
  color: #8E8E8E;
}

.step-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-weight: 500;
    text-align: center;
    color: #464e71;
    margin-bottom: 2rem;
  }

  h2 {
    font-weight: 500;
    color: #464e71;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
  }

  .back-container {
    width: 100%;
    align-items: flex-start;

    .back-button {


      &:hover {
        opacity: 1;
      }
    }
  }

  .button-container {
    text-align: center;
  }

  .forecast-selector {
    text-transform: uppercase;
    font-weight: 600;
    color: #464e71;
  }
}


.v-btn__content {
  padding: 0.75rem;
}

.gender-selector {
  display: block;
  position: relative;
  text-align: center;
  margin-bottom: 2rem;

  .gender-option {
    background-color: #eef3f8;
    border: 1px solid #eef3f8;
    display: inline-block;
    position: relative;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem 1rem;
    line-height: 1.25;
    width: 135px;
    // height: 65px;
    cursor: pointer;
    border-radius: 12px;
    padding: 1rem;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: #d4e2ed;
      border: 1px solid #d4e2ed;
    }

    &.active {
      border: 1px solid #2c6ea9;
    }
  }
}

.v-input--radio-group__input {
  justify-content: center;
}

.add-beneficiary {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  .add-icon {
    border: 1px solid #2c6ea9;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .add-label {
    margin-left: 1rem;
    color: #444444;
    // opacity: 0.5;
  }

  &:hover {
    opacity: 0.5;
  }
}

.b-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .b-label {
    color: #444444;
    opacity: 0.75;
  }

  .b-value {
    color: #444444;
  }
}
</style>