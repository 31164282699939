export default [
  "Mestizo",
  "Abisinio",
  "Africano doméstico",
  "American Curl",
  "American shorthair",
  "American wirehair",
  "Angora turco",
  "Aphrodite-s Giants",
  "Australian Mist",
  "Azul ruso",
  "Bengala",
  "Bobtail japonés",
  "Bombay",
  "Bosque de Noruega",
  "Brazilian Shorthair",
  "British Shorthair",
  "Burmés",
  "Burmilla",
  "California Spangled",
  "Ceylon",
  "Chartreux",
  "Cornish rex",
  "Cymric",
  "Deutsch Langhaar",
  "Devon rex",
  "Don Sphynx",
  "Dorado africano",
  "Europeo común",
  "Gato exótico",
  "German Rex",
  "Habana brown",
  "Himalayo",
  "Khao Manee",
  "Korat",
  "Maine Coon",
  "Manx",
  "Mau egipcio",
  "Munchkin",
  "Ocicat",
  "Ojos azules",
  "Oriental",
  "Oriental de pelo largo",
  "Persa",
  "Peterbald",
  "Pixi Bob",
  "Ragdoll",
  "Sagrado de Birmania",
  "Scottish Fold",
  "Selkirk rex",
  "Serengeti",
  "Seychellois",
  "Siamés",
  "Siamés Moderno",
  "Siamés Tradicional",
  "Siberiano",
  "Snowshoe",
  "Sphynx",
  "Tonkinés",
  "Van Turco",
];
