<template>
  <div>

    <v-row justify="center">
      <v-dialog  class="modal-content" v-model="dialog" width="300px">
        <v-card id="scroll">
          <v-card-text ref="card-Content">
            <div class="text-center">

              <br>
              <br>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                                              color: black; flex: none; order: 1; flex-grow: 0;">Estas son las
                coberturas
                y
                protecciones del</label>
              <br>
              <br>
              <label style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 18px; line-height: 140%; letter-spacing: 0.02em;
                                              color: black; flex: none; order: 1; flex-grow: 0;">Plan <label
                  style="font-family: Helvetica; font-style: normal; font-weight: 600; 
                                              font-size: 20px; line-height: 140%; letter-spacing: 0.02em;
                                              color: rgba(0, 44, 119, 1); flex: none; order: 1; flex-grow: 0; text-transform: uppercase;">{{
                                                info[0][indexInfo]
                                              }}</label> de
                Mascotas</label>
              <br>
              <br>
              <label class="text-center encabezado">Libre elección y sin deducible</label>
              <br>
              <br>
              <label class="texto">Puedes llevar a tu mascota a la
                clínica veterinaria <label class="textoAzul">que tú quieras</label>, sin restricciones. Además,
                todas
                nuestras coberturas son <label class="textoAzul">sin
                  deducible</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Urgencias Médicas</label>
              <br>
              <br>
              <label class="texto">Te devolveremos hasta el <label class="textoAzul">{{ info[1][indexInfo]
              }}</label> de tus
                gastos frente a urgencias veterinarias sin deducibles en cualquier clínica. <label class="textoAzul">Tope
                  UF {{ info[2][indexInfo] }}</label>.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y hasta <label class="textoAzul">{{ info[3][indexInfo] }} veces al
                  año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Consultas Médicas</label>
              <br>
              <br>
              <label class="texto">Reembolsamos las consultas médicas y
                controles de tu mascota con el Veterinario que tú quieras. <label class="textoAzul">Tope UF
                  {{ info[4][indexInfo] }}</label>.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y <label class="textoAzul">{{ info[5][indexInfo] }} vez al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Medicamentos</label>
              <br>
              <br>
              <label class="texto">¿Le recetaron remedios a tu mascota?
                Te damos hasta <label class="textoAzul">$10.000 al mes</label> en reembolsos para medicamentos
                veterinarios con receta.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                contratación y <label class="textoAzul">1 vez al mes</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Vacuna Antirrábica</label>
              <br>
              <br>
              <label class="texto">Te reembolsamos <label class="textoAzul">UF 0,5</label> en el pago de
                la vacuna de tu mascota en cualquier clínica.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">30 días</label> de
                contratación y <label class="textoAzul">1 vez al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Responsabilidad Civil</label>
              <br>
              <br>
              <label class="texto">Tu mascota se portó mal y mordió o
                hizo algún daño a otra persona, te ayudamos en caso de demanda con <label class="textoAzul">UF {{
                  info[8][indexInfo] }}
                </label> si tienes que pagar por los
                daños.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">0 días</label> de
                contratación y <label class="textoAzul">1 vez al año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Telemedicina Veterinaria</label>
              <br>
              <br>
              <label class="texto">¿Tienes dudas de salud de tu mascota?
                Deja que nuestros expertos te ayuden llamando sin costo a la hora que necesites.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y hasta <label class="textoAzul">{{ info[6][indexInfo] }} veces al
                  año</label>.</label>
              <br>
              <br>
              <label class="text-center encabezado">Asesoría Legal Teléfonica</label>
              <br>
              <br>
              <label class="texto">¿Necesitas ayuda con temas legales
                por algo de tu mascota? Deja que nuestros expertos te ayuden llamando sin costo a la hora que
                necesites.</label>
              <br>
              <br>
              <label class="texto">Puedes usarlo a los <label class="textoAzul">15 días</label> de
                contratación y hasta <label class="textoAzul">{{ info[7][indexInfo] }} veces al
                  año</label>.</label>
              <br>

            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                                                border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                                                letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
              elevation="0" text @click="dialog = false">
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row v-if="tipoPlan == false" justify="space-around">
      <v-icon @click="model--">
      </v-icon>
      <v-icon @click="model++">
      </v-icon>
    </v-row>

    <div  v-show="model === 0" style="background:linear-gradient(0deg, #0077A0, #0077A0), #D8D8D8; border-radius:10px; z-index: 1; position: absolute; margin-left: 85%; height: 70%; width: 20%;">
    </div>

    <div  v-show="model === 2" style="background:linear-gradient(0deg, #0077A0, #0077A0), #D8D8D8; border-radius:10px; z-index: 1; position: absolute; height: 70%; width: 15%; left: -10%">
    </div>

    <div  v-show="model === 1" style="background:white; border: 1px solid #DEE1E6; border-radius:10px; z-index: 1; position: absolute; height: 60%; width: 15%; left: -10%; margin-top: 20%;">
    </div>

    <div  v-show="model === 1" style="background:white; border: 1px solid #DEE1E6; border-radius:10px; z-index: 1; position: absolute; margin-left: 84%; height: 60%; width: 20%; margin-top: 20%;">
    </div>

    <v-carousel v-if="tipoPlan == true" hide-delimiter-background  hide-delimiters height="500" v-model="model">
    
      <v-carousel-item>
        <v-sheet style="margin-top: -10%; border-radius: 50%; background-color: green" height="100%" width="350px" tile :class="vista">
          <v-row class="fill-height" align="center" justify="center">

            <div >
              <v-col class="text-center">

                <br>
                <br>
                <br>
                <br>

                <p class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 30px; line-height: 28px;
                color: #FFFFFF; flex: none; order: 0; flex-grow: 0; margin-top: 1%;"> {{
                  planSenior[0].name }}</p>

                <label class="text-center" style="color: white; font-size:32px">{{ formatPrice(planSenior[0].priceCLP)
                }}</label>

                <br>

                <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 15px;
                color: rgba(255, 255, 255, 0.8); flex: none; order: 1; flex-grow: 0;">UF {{
                  planSenior[0].values[0].price
                }}
                  mensual</label>

                <br>

                <hr
                  style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 214px; height: 0px;">

                <br>

                <li class="li_senior"> Telemedicina
                  Veterinaria
                  <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 11px; line-height: 140%; letter-spacing: 0.02em;
                          color: #FFFFFF; flex: none; order: 1; flex-grow: 0; margin-left: -7%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Sin Límite)</p>
                </li>

                <li class="li_senior"> {{
                  planSenior[0].coverage[1].description }}
                  <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 11px; line-height: 140%; letter-spacing: 0.02em;
                          color: #FFFFFF; flex: none; order: 1; flex-grow: 0; margin-left: -7%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ planSenior[0].coverage[1].amount }} UF</p>
                </li>

                <li class="li_senior"> {{
                  planSenior[0].coverage[2].description }} <p></p>
                </li>

                <li class="li_senior"> {{
                            planSenior[0].coverage[3].description }} <p></p>
                </li>

                <li class="li_senior"> {{
                            planSenior[0].coverage[4].description }}
                  <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 11px; line-height: 140%; letter-spacing: 0.02em;
                          color: #FFFFFF; flex: none; order: 1; flex-grow: 0; margin-left: -7%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ planSenior[0].coverage[4].description2 }} UF
                  </p>
                </li>

                <li class="li_senior"> Consulta Médica (1
                  Evento
                  anual) 3 UF <p></p>
                </li>

                <li class="li_senior"> Reembolso en
                  Medicamento
                  Mensual
                  <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 11px; line-height: 140%; letter-spacing: 0.02em;
                          color: #FFFFFF; flex: none; order: 1; flex-grow: 0; margin-left: -7%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(50% de la boleta) $10.000 CLP</p>
                </li>

                <hr
                  style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 214px; height: 0px;">



                  <br><u class="pointer" @click="Info(index)" style="color:#002C77;">VER DETALLE</u><br>

                <div class="plan-info">
                  <v-btn @click="next(planSenior[0])"
                    style="box-sizing: border-box; display: flex;flex-direction: row; justify-content: center; align-items: center; padding: 12px 24px; gap: 10px; position: absolute; background: #FFB600; border: 2px solid #FFB600; border-radius: 8px"
                    elevation="0">
                    Elegir plan
                  </v-btn>
                </div>

              </v-col>

              <br>
              <br>

            </div>

          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <br>
    <br>

    <v-carousel style=" border-radius: 10px;" v-if="tipoPlan == false" hide-delimiter-background hide-delimiters height="700" v-model="model" @change="definirVista()">
      <v-carousel-item  style="border-radius: 10px;" v-for=" (plan, index) in planData" :key="index">
        <v-sheet style="margin-top: -23%; background-color: #F9F9F9; border-radius: 10px;" width="300" tile :class="vista">
          <v-row class="fill-height" align="center" justify="center">

            <div v-if="model == 1" style="background:linear-gradient(0deg, #0077A0, #0077A0), #D8D8D8; border-radius:10px; background-position: center; width: 300px;height: 100%"  >
              <v-col class="text-center">

                <br>
                <br>
                <br>
                <p class="recomendado"> Recomendado</p>
                <br>

                <p class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 30px; line-height: 28px;
                                              color: #FFFFFF; flex: none; order: 0; flex-grow: 0; margin-top: 1%;"> {{
                                                plan.name }}</p>

                <label class="text-center" style="color: white; font-size:32px">{{ formatPrice(plan.priceCLP)
                }}</label>

                <br>

                <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 15px;
                                              color: rgba(255, 255, 255, 0.8); flex: none; order: 1; flex-grow: 0;">UF
                  {{
                    plan.values[0].price
                  }}
                  mensual</label>

                <br>

                <hr
                  style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 214px; height: 0px;">

                <br>

                <li class="li_planM"> Telemedicina
                  Veterinaria
                  <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 11px; line-height: 140%; letter-spacing: 0.02em;
                          color: #FFFFFF; flex: none; order: 1; flex-grow: 0; margin-left: -7%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Sin Límite)</p>
                </li>

                <li class="li_planM"> {{
                            plan.coverage[1].description }}
                  <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 11px; line-height: 140%; letter-spacing: 0.02em;
                          color: #FFFFFF; flex: none; order: 1; flex-grow: 0; margin-left: -7%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ plan.coverage[1].amount }} UF</p>
                </li>

                <li class="li_planM"> {{
                            plan.coverage[2].description }} <p></p>
                </li>

                <li class="li_planM"> {{
                            plan.coverage[3].description }} <p></p>
                </li>

                <li class="li_planM"> {{
                            plan.coverage[4].description }}
                  <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 11px; line-height: 140%; letter-spacing: 0.02em;
                          color: #FFFFFF; flex: none; order: 1; flex-grow: 0; margin-left: -7%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ plan.coverage[4].description2 }} UF</p>
                </li>

                <li class="li_planM"> Consulta Médica (1
                  Evento
                  anual) 3 UF <p></p>
                </li>

                <li class="li_planM"> Reembolso en
                  Medicamento
                  Mensual
                  <p style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 11px; line-height: 140%; letter-spacing: 0.02em;
                          color: #FFFFFF; flex: none; order: 1; flex-grow: 0; margin-left: -7%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(50% de la boleta) $10.000 CLP</p>
                </li>

                <hr
                  style="margin: auto; border: 0.946207px solid #FFFFFF; flex: none; order: 1; flex-grow: 0; width: 214px; height: 0px;">


                  <br><u class="pointer" @click="Info(index)" style="color:white;">VER DETALLE</u><br>
                
                <div class="plan-info">
                  <v-btn @click="next(plan)"
                    style="box-sizing: border-box; display: flex;flex-direction: row; justify-content: center; align-items: center; padding: 12px 24px;
                                                gap: 10px; position: absolute; background: #FFB600; border: 2px solid #FFB600; border-radius: 8px"
                    elevation="0">
                    Elegir plan
                  </v-btn>
                </div>

              </v-col>

              <br>
              <br>

             
            </div>
            <!-- //maneja los planes -->
            <div v-else style="background:white; border-radius:10px; background-position: center; width: 300px;height: 100%  ;border: 1px solid #DEE1E6; margin-top: 22%;" >
              <v-col class="text-center">

                <br>
                
                <label class="text-center" style="color: #5A5A5A; font-size:30px">{{ plan.name }}</label>
                <br>

                <label class="text-center" style="color: #5A5A5A; font-size:15px">Libre elección</label>

                <br>

                <label class="text-center" style="color: #2670CA; font-size:32px">{{ formatPrice(plan.priceCLP)
                }}</label>

                <br>

                <label class="text-center" style="font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 12px; line-height: 15px;
                                              color: rgba(0, 44, 119, 1); flex: none; order: 1; flex-grow: 0;">UF {{
                                                plan.values[0].price
                                              }}
                  mensual</label>

                <br>

                <hr style="box-sizing: border-box; width: 216px; height: 0px; opacity: 0.3; border: 1px solid rgba(0, 0, 0, 0.3); flex: none;
                                              order: 1; align-self: stretch; flex-grow: 0; margin: auto;">

                <br>

                <li class="li_plan_S_L">
                  Telemedicina Veterinaria (Sin Límite) <p></p>
                </li>

                <li class="li_plan_S_L">
                  {{ plan.coverage[1].description }}
                  <p
                    style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 140%; letter-spacing: 0.02em;
                                          color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -15%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                      plan.coverage[1].amount }} UF</p>
                </li>

                <li class="li_plan_S_L">
                  {{ plan.coverage[2].description }} <p></p>
                </li>

                <li class="li_plan_S_L">
                  {{ plan.coverage[3].description }} <p></p>
                </li>

                <li class="li_plan_S_L">
                  {{ plan.coverage[4].description }}
                  <p
                    style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 140%; letter-spacing: 0.02em;
                                          color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -15%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
                      plan.coverage[4].description2 }} {{ plan.coverage[4].amount }} UF</p>
                </li>

                <li v-if="model == 1" class="li_plan_S_L">
                  Consulta Médica (1 Evento anual) 1 UF<p></p>
                </li>

                <li v-if="model == 3" class="li_plan_S_L">
                  Consulta Médica (1 Evento anual) 2 UF<p></p>
                </li>

                <li v-if="model != 0" class="li_plan_S_L">
                  Reembolso en Medicamento Mensual
                  <p
                    style="text-align: left; font-family: Helvetica; font-style: normal; font-weight: 400; font-size: 13px; line-height: 140%; letter-spacing: 0.02em;
                                  color: rgba(0, 0, 0, 0.5); flex: none; order: 1; flex-grow: 0; text-align: left; margin-left: -15%;">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (50% de la boleta)
                    $10.000 CLP</p>
                </li>
                

                <hr style="box-sizing: border-box; width: 216px; height: 0px; opacity: 0.3; border: 1px solid rgba(0, 0, 0, 0.3); flex: none;
                                              order: 1; align-self: stretch; flex-grow: 0; margin: auto;">

                
            
                
                <br><u class="pointer" @click="Info(index)" style="color:#002C77;">VER DETALLE</u>
                
                <!-- //Plan recomendado -->
                <div v-if="model == 0" class="plan-info2">
                  <v-btn @click="next(plan)"
                    style="box-sizing: border-box; display: flex; flex-direction: row; justify-content: center; align-items: center; padding: 12px 24px;
                                                  gap: 10px; width: 214px; height: 39px; background: #FFB600; border-radius: 8px; flex: none; order: 8; flex-grow: 0; color: #002C77; "
                    elevation="0">
                    Elegir plan
                  </v-btn>
                </div>
                

                <div v-else class="plan-info2">
                  <v-btn @click="next(plan)"
                    style="box-sizing: border-box; display: flex; flex-direction: row; justify-content: center; align-items: center; padding: 12px 24px;
                                                  gap: 10px; width: 214px; height: 39px; background: #FFB600; border-radius: 8px; flex: none; order: 8; flex-grow: 0; color: #002C77; margin: auto"
                    elevation="0">
                    Eligir plan
                  </v-btn>

                 <br> 
                </div>

              </v-col>

              
            </div>

          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

  </div>
</template>

<script>
import Quote from "@/views/Quote.vue";
import ShieldService from "@/services/shield.service.js";

export default {
  views: {
    Quote,
  },
  name: "Plan",
  props: ["planData", "planSenior", "tipoPlan"],
  data() {
    return {
      formatter: new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }),
      stepId: null,
      e1: 3,
      tab: 0,
      vista: 'planesvista',
      model: 1,
      info: [['Plan S',  'Plan M', 'Plan L', "Mascota Senior"], ['40%', '60%','80%', '80%'], ['50', '100', '250', '250'], ['2', '2', '3', '3'], ['1', '2', '3', '3'], ['1', '1', '2', '2'], ['3', '4', '6', '6'], ['2', '2', '4', '4'], ['5', '10', '15', '15']],
      indexInfo: '0',
      dialog: false,
      userData: {
        name: null,
        surname: null,
        serialNumber: null,
        rut: null,
        region: {
          name: null,
        },
        commune: {
          name: null,
        },
        address: null,
        email: null,
        phoneNumber: null,
        hasAcode: null,
        couponId: null,
        dates: null,
      },
    }
  },
  async mounted() {
    this.stepId = localStorage.getItem('stepId')

    if (this.tipoPlan == true) {
      this.model = 5
      this.vista = 'planesvista3'
    } else {
      this.tipoPlan = false
    }
  },
  methods: {
    async SendCouponID() {
      this.planData = await ShieldService.getPlans( //Espera el código ingresado en el step 1
        this.userData.couponId
      );
      if (this.planData.length === 0) {
        this.$alert("No se encontraron planes", "Información", "info")
      }
    },
    async Info(index) {
      console.log(this.planData);
      
      if (this.planData[index] == 'Mascota Senior') {
        this.indexInfo = 4
      } else {
        this.indexInfo = index
      }
      this.dialog = true;
      document.getElementById("scroll").scrollIntoView({behavior: 'smooth'});
    },
    InfoSenior(index) {
      this.indexInfo = index
      this.dialog = true
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async next(plan) {
      localStorage.setItem("planId", plan._id);
      localStorage.setItem("couponId", this.userData.couponId);

      var values;
      try {
        values = {
          plan: plan._id
        }
        var carro = await ShieldService.createStep(values, 2, 0, this.stepId)
        this.stepId = carro.data.data._id
      } catch (error) {
        console.log("Cart Fail");
      }

      window.location.pathname = "/datos";
    },
    backPlan(index) {
      if (index > 0) {
        this.tab = index - 1
      }
    },
    definirVista() {
      if (this.model == 0) {
        this.vista = 'planesvista5'
      } else if (this.model == 1) {
        this.vista = 'planesvista'
      } else if (this.model == 2) {
        this.vista = 'planesvista2'
      } 
    },
  },
};
</script>

<style scoped>
.texto {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.recomendado {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 5px 25px;
gap: 8px;
margin: auto;
width: 152px;
height: 27px;
left: 112px;
top: 30px;
font-family: Helvetica;
background: #FFBE00;
border-radius: 5px;
}
.textoAzul {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400px;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.02em;
  color: rgba(0, 44, 119, 1);
  flex: none;
  order: 1;
  flex-grow: 0;
}

.v-carousel--hide-delimiter-background .v-carousel__controls {
  display: none !important;
}

.v-carousel__controls {
  display: none !important;
}

.carousel .carousel__controls { display: none; }

.my-carousel .v-carousel__controls {
  visibility: hidden !important;
  display: none !important;
}

.encabezado {
  color: rgba(0, 44, 119, 1);
  font-size: 18px;
  font-weight: 600;
}

/* Plan M Recomendado */
.planesvista {

}

/* Plan L */
.planesvista2 {

 
}

/* Plan S */
.planesvista5 {

}



.plan-info {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plan-info2 {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ribbon {
  font-size: 16px !important;
  /* This ribbon is based on a 16px font side and a 24px vertical rhythm. I've used em's to position each element for scalability. If you want to use a different font size you may have to play with the position of the ribbon elements */

  width: 50%;

  position: relative;
  background: #d40606;
  color: #fff;
  text-align: center;
  padding: 1em 2em;
  /* Adjust to suit */
  margin: 2em auto 3em;
  /* Based on 24px vertical rhythm. 48px bottom margin - normally 24 but the ribbon 'graphics' take up 24px themselves so we double it. */
}

.ribbon:before,
.ribbon:after {
  content: "";
  position: absolute;
  display: block;
  bottom: -1em;
  border: 1.5em solid #d40606;
  z-index: -1;
}

.ribbon:before {
  left: -2em;
  border-right-width: 1.5em;
  border-left-color: transparent;
}

.ribbon:after {
  right: -2em;
  border-left-width: 1.5em;
  border-right-color: transparent;
}

.ribbon .ribbon-content:before,
.ribbon .ribbon-content:after {
  content: "";
  position: absolute;
  display: block;
  border-style: solid;
  border-color: #d40606 transparent transparent transparent;
  bottom: -1em;
}

.ribbon .ribbon-content:before {
  left: 0;
  border-width: 1em 0 0 1em;
}

.ribbon .ribbon-content:after {
  right: 0;
  border-width: 1em 1em 0 0;
}

.li_senior{
  font-family: Helvetica;
  font-style: normal; 
  font-weight: 400; 
  font-size: 11px; 
  line-height: 140%; 
  letter-spacing: 0.02em;
  color: #FFFFFF; 
  flex: none; 
  order: 1; 
  flex-grow: 0; 
  text-align: left;
}

.li_planM{
  font-family: Helvetica; 
  font-style: normal; 
  font-weight: 400; 
  font-size: 11px; 
  line-height: 140%; 
  letter-spacing: 0.02em;                        
  color: #FFFFFF; 
  flex: none; 
  order: 1; 
  flex-grow: 0; 
  text-align: left; 
  margin-left: 10%;
}

.li_plan_S_L{
  font-family: Helvetica; 
  font-style: normal; 
  font-weight: 400; 
  font-size: 12px; 
  line-height: 140%; 
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.5); 
  flex: none; order: 1; 
  flex-grow: 0; 
  text-align: left; 
  margin-left:10%
}


</style>