<template>
    <v-container>

        <div class="text-center">

            <h4 style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 25px; letter-spacing: -0.01em;
                text-transform: uppercase; color: #FFB600; text-transform: uppercase;">UPS;
            </h4>

            <h4 style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 25px; letter-spacing: -0.01em;
                text-transform: uppercase; color: #FFB600; text-transform: uppercase;">Ha ocurrido un <P style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 20px; line-height: 25px; letter-spacing: -0.01em;
                text-transform: uppercase; color: #FFB600; text-transform: uppercase;"> error inesperado</P>
            </h4>

        </div>

        <br>
        <br>

        <div :class="image">
            <v-card>
                <v-img contain max-height="300" :src="ErrorImage" max-width="300" style="margin:auto;" />
            </v-card>
        </div>

        <br>
        <br>

        <div :class="image">
            <v-card class="text-center">
                <label
                    style="font-family: Arial; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px; text-align: center; color: #232323; opacity: 0.75;">
                    Al parecer ocurrio un error con el sistema de
                    pago. pero no te preocupes que debe ser
                    temporal.
                </label>
            </v-card>
        </div>

        <br>

        <div class="text-center">

            <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: #FFB600; box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white;" elevation="0"
                @click="returnPage(1)">
                <v-icon color="white" dark left>
                    mdi-arrow-left
                </v-icon>
                Volver al resumen
            </v-btn>

            <br>
            <br>

            <v-btn style="padding: 12px 15px; gap: 10px; width: 345px; height: 50px; background: rgba(0, 44, 119, 1); box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                        border-radius: 12px; text-transform:inherit; color: white;" elevation="0"
                @click="returnPage(0)">
                <v-icon color="white" dark left>
                    mdi-arrow-left
                </v-icon>
                Quieres ver otros seguros?
            </v-btn>

        </div>

    </v-container>
</template>

<script>
import ErrorImage from "@/assets/images/Error.png";

export default {
    data() {
        return {
            image: null,
            ErrorImage,
            screen: window.screen.width,
        }
    },
    async mounted() {
        if (this.screen > 800) {
            this.image = 'image'
        } else {
            this.image = 'image2'
        }
    },
    methods: {
        returnPage(index) {
            if (index == 0) {
                window.location.href = 'https://enlineaseguros.cl/';

            } else if (index == 1) {
                window.location.pathname = '/resumen';
            }
        }
    }

}
</script>

<style lang="css" scoped>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}

.image {
    width: 20%;
    margin: auto;
}

.image2 {
    width: 100%;
    margin: auto;
}
</style>
