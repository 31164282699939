<template>
  <v-container>
    <div class="tituloOnco text-center">
      <h1 class="etiqueta">Seguro MASCOTA SALUD</h1>
      <img :src="Rosa" alt="Image 1" class="logo-rosa">
      <div class="vertical-line"></div>
    </div>
  </v-container>
</template>

<script>
import Rosa from '@/assets/images/VectorRosa.png'; export default {
  name: "Titulo",
  data: () => {
    return {
      Rosa,
    };
  },
  mounted() { },
};
</script>

<style lang="scss">
.v-container {
  justify-content: center;
  align-items: center;
  /* Agregamos alineación vertical al centro */
}


.tituloOnco {
  position: relative;
  /* Agregamos posición relativa */
  width: 100%;
  max-width: 73%;
  min-width: px;
  height: 57px;
  border-radius: 10px;
  margin: auto;
  background-image: url("~@/assets/images/TituloBack.png");
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
}

@media (min-width: 767px) and (max-width: 1264px) {
  .tituloOnco {
    position: relative;
    /* Agregamos posición relativa */
    width: 100%;
    max-width: 100%;
    min-width: px;
    height: 57px;
    border-radius: 10px;
    margin: auto;
    background-image: url("~@/assets/images/TituloBack.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .tituloOnco {
    position: absolute;
    margin-top: -3%;
    padding-bottom: 12%;
    max-width: 100%;
    left: 0;
    width: 100vw;
    height: 65px;
    background-image: url("~@/assets/images/TituloBackMovil2.png");
    background-size: 200%;
    background-position: center;
    background-repeat: no-repeat;
    white-space: nowrap;
    border-radius: 1px;
  }
}

.logo-rosa {
  position: absolute;
  /* Agregamos posición absoluta */
  top: 50%;
  /* Centramos verticalmente */
  left: 7.5%;
  /* Ajustamos el espacio izquierdo */
  transform: translateY(-50%);
  /* Centramos verticalmente */
  max-width: 100%;
  max-height: 43px;
}

@media (min-width: 767px) and (max-width: 1264px) {
  .logo-rosa {
    position: absolute;
    /* Agregamos posición absoluta */
    top: 50%;
    /* Centramos verticalmente */
    left: 6%;
    /* Ajustamos el espacio izquierdo */
    transform: translateY(-50%);
    /* Centramos verticalmente */
    max-width: 100%;
    max-height: 43px;
  }
}

@media (max-width: 767px) {
  .logo-rosa {
    position: absolute;
    /* Agregamos posición absoluta */
    top: 50%;
    /* Centramos verticalmente */
    left: 5%;
    /* Ajustamos el espacio izquierdo */
    transform: translateY(-50%);
    /* Centramos verticalmente */
    max-width: 90%;
    height: 28px;
  }
}

.etiqueta {
  position: absolute;
  /* Agregamos posición absoluta */
  top: 50%;
  /* Centramos verticalmente */
  left: 50%;
  /* Centramos horizontalmente */
  transform: translate(-50%, -50%);
  /* Centramos vertical y horizontalmente */
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #FFFFFF;
}

@media (max-width: 767px) {
  .etiqueta {
    position: absolute;
    /* Agregamos posición absoluta */
    top: 50%;
    /* Centramos verticalmente */
    left: 55%;
    /* Centramos horizontalmente */
    transform: translate(-50%, -50%);
    /* Centramos vertical y horizontalmente */
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
  }
}

.vertical-line {
  position: absolute;
  top: 50%;
  left: 21%;
  /* Ajustamos el espacio derecho */
  transform: translate(-50%, -50%);
  /* Centramos vertical y horizontalmente */
  width: 1.5px;
  height: 31px;
  background-color: white;
}

@media (min-width: 767px) and (max-width: 1264px) {
  .vertical-line {
    position: absolute;
    top: 50%;
    left: 16%;
    /* Ajustamos el espacio derecho */
    transform: translate(-50%, -50%);
    /* Centramos vertical y horizontalmente */
    width: 1.3px;
    height: 29px;
    background-color: white;
  }
}

@media (max-width: 767px) {
  .vertical-line {
    position: absolute;
    top: 50%;
    left: 16%;
    /* Ajustamos el espacio derecho */
    transform: translate(-50%, -50%);
    /* Centramos vertical y horizontalmente */
    width: 1.3px;
    height: 25px;
    background-color: white;
  }
}

.h1 {
  max-width: 120%;
}</style>