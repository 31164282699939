import ageData from "../utils/age-data";

export default class AgeService {
  static async getAges() {
    try {
      let ages = ageData;
      return ages;
    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener las edades",
      };
    }
  }
}
